import React from "react"
import { graphql } from "gatsby"
import Filtertron from "../components/Filtertron"

const ServePage = ({data}) => (
  <Filtertron
    context                   = "serve"
    seoTitle                  = "Serve with Us"
    noRecordsPageTitlePrefix  = "Nothing Available"
    noRecordsPageTitle        = "Come back soon to see if any new positions are listed"
    hasRecordsPageTitlePrefix = ""
    // hasRecordsPageTitle       = "Discover opportunities to serve in our community"
    hasRecordsPageTitle       = "Join us in serving our community at&nbsp;Hillside"
    noRecordsIndexTitle       = "No Results"
    noRecordsIndexText        = "trying another selection to find a position to serve in."
    collection                = {data.allDatoCmsTask.edges}
  />
)

export default ServePage


// GraphQL
// -------

export const query = graphql`
  query TaskQueryIndex {
    allDatoCmsTask(
      sort : {fields : position, order : ASC}
    ) {
      edges {
        node {
          ...TaskFragment
        }
      }
    }
  }
`
